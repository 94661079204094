import React from "react"

import {
  List,
  ListItem,
  Stack,
  Text,
  TextLink,
} from "@kiwicom/orbit-components"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

import symbols from "./images/tachograph-symbols.png"

const Page = () => (
  <DriveLayout title="EU Driving Rules">
    <Hero title="EU Driving Rules" />
    <Container size="medium">
      <Stack spacing="XLarge">
        <EmberCard title="Tachograph Rules">
          <EmberCardSection>
            <Stack>
              <Text>
                As an Ember driver, all of your work must be logged with a
                tachograph. This allows for verification that you have been
                sticking to EU rules on drivers' hours. A Tachograph logs the
                type of work that you have been doing between the following
                categories:
              </Text>
              <img title="Tachograph Symbols" src={symbols} />
              <Text>
                The GOV.UK website has a{" "}
                <TextLink
                  external
                  href="https://www.gov.uk/guidance/drivers-hours-passenger-vehicles/4-tachograph-rules"
                >
                  guide to all the rules on using a Tachograph
                </TextLink>{" "}
                but we've summarised the key points here:
              </Text>
              <List>
                <ListItem>
                  Driving: Whilst driving a vehicle, your digital tachograph
                  card must be inserted into Slot 1 on the tachograph.
                </ListItem>
                <ListItem>
                  Double Manning: If you are double manning, Slot 2 should be
                  used by the person who is not driving. It will automatically
                  set your activity to "Available"
                </ListItem>
                <ListItem>
                  Manual Entries: Work conducted outside a vehicle should be
                  logged by making manual entries the next time you insert your
                  card into a tachograph.
                </ListItem>
                <ListItem>
                  Print-Outs: Make sure there are enough tachograph rolls on
                  board to make print-outs. If you've had to take a print-out to
                  correct an incorrect record, explain an infringement or
                  because you've been driving without a card (for a valid
                  reason) then you must keep it with you for 28 days.
                </ListItem>
                <ListItem>
                  Forgotten Cards: If you forget your tachograph card for a
                  shift, you must phone the driver number to report the issue
                  and then return home to collect your card. You can not drive.
                </ListItem>
                <ListItem>
                  Lost, Damaged or Stolen Cards: You can temporarily drive
                  without a card if your card has been reported as lost, damaged
                  or stolen and you are waiting for a new one. Keep signed
                  print-outs during this period.
                </ListItem>
                <ListItem>
                  Faulty Tachographs: You can also drive without a card if the
                  tachograph has a fault (Ember has seven days to repair any
                  faults). Make sure the fault has been reported and log your
                  work manually during this period, keeping the records with you
                  for 28 days.
                </ListItem>
                <ListItem>
                  Downloads: You must make your card available for us to
                  download so we can check compliance. Most of our vehicles do
                  this automatically so if you are a regular driver, you are
                  unlikely to have to think about this. However, if you are an
                  occassional driver you will need to make sure we get an upload
                  at the end of your shift. This can be done by pressing the
                  silver tachograph download button on an equipped coach and
                  waiting for the light to stop flashing or via an iPad dongle.
                </ListItem>
              </List>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Drivers' Hours Rules">
          <EmberCardSection>
            <Stack>
              <Text>
                You should be familiar with EU drivers' hours rules from your
                CPC training but the rules are complex so it's worth reviewing
                them every now and again to make sure you are up to speed. The
                GOV.UK website has an{" "}
                <TextLink
                  external
                  href="https://www.gov.uk/guidance/drivers-hours-passenger-vehicles/1-eu-and-aetr-rules-on-drivers-hours"
                >
                  excellent overview
                </TextLink>{" "}
                to all the rules that's worth a read.
              </Text>
              <Text>
                We'll assign you shifts that try to avoid any risk of
                infringements but unforeseen delays can create a risk. In
                addition, any other work you do outside of Ember will be seen as
                interrupting your rest so it's important you let us know about
                this and manage it to avoid infringements.
              </Text>
              <Text>
                The rules that are most likely to cause you issues are
                summarised below:
              </Text>
              <List>
                <ListItem>
                  Breaks: You must take a rest of at least 45 minutes after 4.5
                  hours of driving. This means having your tachograph set to
                  rest for the full 45 minutes and not touching it in this
                  period, otherwise you'll get an infringement. You can split
                  this into a 15 minute break and then a 30 minute break but not
                  the other way around. To make sure you get your full 45
                  minutes, take a note of the time showing on the tachograph
                  when starting your rest period and then do not use it again
                  until its clock is showing 46 minutes after that point. If you
                  are doing other work, you must take a break at least every six
                  hours.
                </ListItem>
                <ListItem>
                  Daily Driving Limit: You can only drive a maximum of nine
                  hours per day, or up to ten hours twice a week.
                </ListItem>
                <ListItem>
                  Daily Rest: You must have a rest period of at least 11 hours
                  between shifts. This can be reduced to nine hours up to twice
                  a week.
                </ListItem>
                <ListItem>
                  Weekly Rest: You must take a weekly rest of 45 hours after six
                  days (6 x 24 hours from your last weekly rest). This can be
                  reduced to 24 hours every other week.
                </ListItem>
                <ListItem>
                  Night Work Limit: If you are working overnight (any of your
                  work from 1am - 5am), you cannot work over 10 hours in a 24
                  hour period. This includes all work, not just driving, but
                  excludes breaks.
                </ListItem>
              </List>
              <Text>
                This is not a complete list. As a professional driver, it's your
                personal responsibility to understand the full rules and avoid
                infringments, in addition to our responsibility as an employer.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Page
